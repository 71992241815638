import Alpine from "alpinejs";
import { gsap } from "gsap";

function loadScript(url, callback) {
  var script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    // IE
    script.onreadystatechange = function () {
      if (script.readyState == "loaded" || script.readyState == "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    // Other browsers
    script.onload = function () {
      callback();
    };
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

Alpine.data("bookvisitWidget", () => {
  return {
    showWidget: false,

    aniWidget(show) {
      if (show) {
        console.log(this.$refs.container);
        gsap.fromTo(
          this.$refs.container,
          { opacity: 0 },
          {
            duration: 2,
            delay: 0.3,
            opacity: 1,
            ease: "power4.out",
          }
        );
        gsap.to(this.$refs.button, {
          duration: 1,
          opacity: 0,
          ease: "power4.out",
        });
        gsap.delayedCall(1, () => {
          this.$refs.widgetWrapper.classList.add("safari-fix");
        });
      } else {
        this.$refs.widgetWrapper.classList.remove("safari-fix");

        gsap.to(this.$refs.container, {
          duration: 1,
          opacity: 0,
          ease: "power4.out",
        });
        gsap.to(this.$refs.button, {
          duration: 1,
          opacity: 1,
          ease: "power4.out",
        });
      }
    },

    init() {
      this.$watch("showWidget", (value) => this.aniWidget(value));

      const self = this;
      loadScript(
        "https://online.bookvisit.com/Content/flexwidget/FlexWidgetFullScripts.min.js",
        () => {
          // This function will be executed after the script has finished loading
          console.log("Script loaded.");
          const baseUrl = "https://book.solhemhotel.se/v2/widget/getwidget";
          (function ($) {
            $.ajax({
              dataType: "json",
              url:
                baseUrl +
                "?channelId=0e72b26d-775e-49cb-bfca-1f9eba79f7fc&openOption=redirect&culture=sv-SE&layout=Standard2Columns&containerId=widgetContainer&displayPromoCode=false&displayCorpCode=false&displayIATACode=false&displayCalendarStartsAtFirstAvailableDay=false&currency=SEK",
              xhrFields: { withCredentials: true },
              success: function (data) {
                $(self.$refs.container).html(data.Widget);
                self.$refs.widgetWrapper
                  .closest(".cta-block")
                  .classList.add("z-20");
                self.showWidget = true;
              },
            });
          })(bvJQuery);
        }
      );
    },
  };
});
